<template>
  <div class="landing-page">
    <div class="content">
      <Header />
      <Slider />
      <PlatformOfferings />
      <TheCompany />
      <ContactUs />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../sections/Header.vue";
import Slider from "../sections/Slider.vue";
import PlatformOfferings from "../sections/PlatformOfferings.vue";
import TheCompany from "../sections/TheCompany.vue";
import ContactUs from "../sections/ContactUs.vue";
import Footer from "../sections/Footer.vue";

export default {
  name: "LandingPage",
  components: {
    Header,
    Slider,
    PlatformOfferings,
    TheCompany,
    ContactUs,
    Footer,
  },
};
</script>

<style scoped>
.landing-page {
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
}

.content {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>