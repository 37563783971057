<template>
  <div class="overlap-group">
    <div class="div-wrapper">
      <div class="frame-wrapper">
        <div class="group-12">
          <div class="group-20">
            <div class="overlap-6">
              <div class="group-21">
                <p class="unleashing-latin">
                  Unleashing Latin America's <br />
                  Luxury Market Worldwide!
                </p>
                <p class="prepare-for-the">
                  <span class="span">
                    Prepare for the grand debut of SURe, where luxury meets accessibility. Stay tuned for an exclusive
                    array of premium products and services, effortless transactions, and a user-centric platform
                    tailored
                    to your needs.
                  </span>
                  <br><br>
                  <span class="text-wrapper-12">COMING SOON!</span>
                </p>
              </div>
            </div>
            <img class="dsc-2" alt="Dsc" src="../../static/img/dsc01337-1.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheCompany",
};
</script>

<style scoped>
.overlap-group {
  width: 100%;
}

.div-wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.frame-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.frame-wrapper .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.frame-wrapper .frame-instance {
  flex: 0 0 auto !important;
}

.frame-wrapper .group-12 {
  position: relative;
  width: 100%;
}


.frame-wrapper .group-18 {
  height: 50px;
  left: 130px;
  position: absolute;
  top: 154px;
  width: 300px;
}

.frame-wrapper .rectangle-12 {
  width: 100%;
}

.frame-wrapper .text-wrapper-11 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 130px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 1020px;
}

.frame-wrapper .group-19 {
  height: 31px;
  left: 130px;
  position: absolute;
  top: 330px;
  width: 105px;
}

.frame-wrapper .send-wrapper {
  background-color: var(--black-principal);
  height: 31px;
  position: relative;
  width: 103px;
}

.frame-wrapper .send {
  color: var(--ffffff);
  font-family: "Futura Hv BT-Heavy", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 9px;
  letter-spacing: 1.8px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 85px;
}

.frame-wrapper .dropdown-instance {
  left: 470px !important;
  position: absolute !important;
  top: 154px !important;
}

.frame-wrapper .dsc {
  height: 272px;
  left: 802px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 348px;
}

.frame-wrapper .group-20 {
  width: 100%;
  display: flex;
}

.frame-wrapper .overlap-6 {
  background-color: var(--black-principal);
  height: 500px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.frame-wrapper .group-21 {
  height: 199px;
  width: 388px;
}

.frame-wrapper .unleashing-latin {
  color: var(--ffffff);
  font-family: "Futura Hv BT-Heavy", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.frame-wrapper .prepare-for-the {
  color: var(--ffffff);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 384px;
}

.frame-wrapper .span {
  font-weight: 300;
}

.frame-wrapper .text-wrapper-12 {
  font-family: "Futura Bk BT-Regular", Helvetica;
  margin-top: 10px;
}

.frame-wrapper .dsc-2 {
  height: 500px;
  object-fit: cover;
  width: 100%;
}

.frame-wrapper .send-2 {
  color: var(--ffffff);
  font-family: "Futura Hv BT-Heavy", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.8px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 85px;
}

@media (max-width: 700px) and (min-width: 320px) {

  .frame-wrapper .group-20{
    flex-wrap: wrap;
  }

}

</style>
