<template>
  <div class="accordion">
    <div v-for="(item, index) in items" :key="index" class="accordion-item">
      <div class="accordion-header" @click="toggleItem(index)">
        <div class="accordion-title">{{ item.title }}</div>
        <i :class="{ 'fas fa-chevron-down': activeItem === index, 'fas fa-chevron-right': activeItem !== index }"></i>
      </div>
      <div v-if="activeItem === index" class="accordion-content">
        <slot :name="`content-${index}`"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // activeItem: null,
      activeItem: 0,
    };
  },
  methods: {
    toggleItem(index) {
      this.activeItem = this.activeItem === index ? null : index;
    },
  },
};
</script>

<style scoped>
.accordion {
  border-radius: 4px;
  padding: 5px 0px 25px 0px;
}

.accordion-item {
  border-bottom: 1px solid #000;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-content {
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: stretch;
  align-items: flex-start;
}

.accordion-title {
  color: #000000;
  font-family: "Futura Md BT-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
</style>
