<template>
  <div class="overlap">
    <div class="frame-4">
      <div class="OfferingsTitle">
        <p class="text-wrapper-24">
          Purveyor of Luxury Items, Fine Goods and Treasures found throughout Latin America.
        </p>
      </div>
      <Accordion :items="accordionItems">
        <template #content-0>
          <div class="ItemAccordion">
            <div class="LuxuryGoodsImg">
              <img class="LuxuryGoodsIcon" alt="Group" src="../../static/img/group-5.png" />
            </div>
            <p class="luxury-goods">
              Luxury Goods: Exclusive partnerships with Latin American luxury brands to offer premium products.
            </p>
          </div>
          <div class="ItemAccordion">
            <div class="HandmadeItemsImg">
              <img class="HandmadeItemsIcon" alt="Vector" src="../../static/img/vector-2.svg" />
            </div>
            <p class="handmade-items-a">
              Handmade Items: A curated selection of handmade goods from small businesses and individual artisans,
              emphasizing quality and craftsmanship.
            </p>
          </div>
          <div class="ItemAccordion">
            <div class="BulkPurchasesImg">
              <img class="BulkPurchasesIcon" alt="Group" src="../../static/img/group-6.png" />
            </div>
            <p class="bulk-purchases">
              Bulk Purchases: Facilitation of large orders for businesses worldwide, offering competitive pricing and
              logistics services.
            </p>
          </div>
          <div class="ItemAccordion">
            <div class="LogisticsServicesImg">
              <img class="LogisticsServicesIcon" alt="Vector" src="../../static/img/vector-3.svg" />
            </div>
            <p class="LogisticsServicesTxt">
              Logistics Services: A comprehensive suite of logistics services, including warehousing, order fulfillment,
              and
              international shipping from our hub in Colombia.
            </p>
          </div>
          <div class="group-27">
            <div class="group-29" />
            <div class="group-28" />
          </div>
        </template>
        <template #content-1>
          <div class="board-content">
            <div class="board-content-item">
              <div class="board-text">Carolina Durán SURe CEO</div>
              <div class="board-content-item-profile">
                <img alt="Carolina Duran" class="board-content-img" src="../../static/img/Carolina.jpeg" />
                <p class="board-text">Born in Colombia, Carolina Duran is an Economist from the University of Barcelona
                  and the University
                  of
                  the Andes, with a Master’s in International Relations and Public Administration from Columbia
                  University, and studies on Social Change at Stanford University. With over 20 years of experience, she
                  has worked in Business Development at Citibank in Bangalore, advised the Colombian Banking
                  Association,
                  and served as First Secretary in the Ministry of Foreign Affairs, where she created ‘Colombia Nos
                  Une.’
                  As Executive Director of Corporación Ventures, she supported over 30,000 ventures and 300 companies,
                  generating more than 12,000 jobs annually. Recognized as one of Colombia's top 100 leaders in 2019,
                  she
                  has channeled over $100 million in VC to the Colombian entrepreneurial ecosystem. As Secretary of
                  Economic Development of Bogota until 2022, she advanced economic reactivation and urban development
                  strategies, earning accolades like Woman of the Decade by the WEF in 2022 and the ‘Order for
                  Entrepreneurial Merit’ in 2023. Carolina is an international speaker, innovation advisor, and member
                  of
                  several boards, including the Global Innovation Council. She is a Fulbright, Maguire, and Colfuturo
                  Scholar, and is based in Bogota as a mother of twins, global citizen, and digital nomad.</p>
              </div>
            </div>
            <div class="board-content-item">
              <div class="board-text">Federico Lara SURe CTO</div>
              <div class="board-content-item-profile">
                <img alt="Federico Lara" class="board-content-img" src="../../static/img/Federico.jpeg" />

                <p class="board-text">Cross Border e-Commerce Creator, Global Marketplace Specialist, Payment Architect,
                  and Customer
                  Conversion Strategist.

                  Former CTO at FedEx Cross Border — co-created Bongo International in 2007, which was acquired by FedEx
                  in 2015. Responsible for all Global e-Commerce Technology Developments and New Product Initiatives.

                  Lara's previous experience in web production and technology creation involved working with teams and
                  projects at IBM Websphere, Coca-Cola, and MIT Media Lab, among others.

                  Lara is an avid supporter of the developer and startup community and is an active member in the
                  following organizations: NY Tech Alliance, Startup Weekend, and BBVA Open Talent. Lara holds a Music
                  Business and Technology degree from Berklee College of Music in Boston, and he studied Finance and
                  International Business at Harvard Extension School.</p>
              </div>
            </div>
          </div>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>

import Accordion from '../components/Accordion.vue';

export default {
  name: "Overlap",
  components: {
    Accordion
  },
  data() {
    return {
      accordionItems: [
        { title: 'Platform Offerings' },
        { title: 'The Management Board' },
      ],
    };
  },
};
</script>

<style>
.board-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: center;
}

.board-content-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0px 15px 5px;
}

.board-content-img {
  width: 20%;
  margin: 20px 20px 5px 0px;
  border-radius: 59%;
}

.board-content-item-profile {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.board-text {
  color: #000000;
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
}

/* Pagina */
.overlap {
  width: 90%;
}

.OfferingsTitle {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.overlap .frame-4 {
  width: 100%;
}

.overlap .text-wrapper-24 {
  color: #000000;
  font-family: "Futura Md BT-Medium", Helvetica;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: 620px;
}

.ItemAccordion {
  display: flex;
  width: 50%;
  padding: 20px 0px 20px 0px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.overlap .LogisticsServicesTxt {
  color: #000000;
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  width: 380px;
  padding-left: 20px;
}

.overlap .luxury-goods {
  color: #000000;
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  width: 380px;
  padding-left: 20px;
}

.overlap .handmade-items-a {
  color: #000000;
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  width: 380px;
  padding-left: 20px;
}

.overlap .bulk-purchases {
  color: #000000;
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  width: 380px;
  padding-left: 20px;
}

.overlap .LuxuryGoodsImg {
  background-color: var(--gray-6);
  border-radius: 45px;
  height: 90px;
  width: 90px;
  padding: 25px;
}

.overlap .LuxuryGoodsIcon {
  height: 35px;
  width: 40px;
}

.overlap .HandmadeItemsImg {
  background-color: var(--gray-6);
  border-radius: 45px;
  height: 90px;
  width: 90px;
  padding: 25px;
}

.overlap .HandmadeItemsIcon {
  height: 40px;
  width: 40px;
}

.overlap .LogisticsServicesImg {
  background-color: var(--gray-6);
  border-radius: 45px;
  height: 90px;
  width: 90px;
  padding: 25px;
}

.overlap .LogisticsServicesIcon {
  height: 37px;
  width: 38px;
}

.overlap .BulkPurchasesImg {
  background-color: var(--gray-6);
  border-radius: 45px;
  height: 90px;
  width: 90px;
  padding: 25px;
}

.overlap .BulkPurchasesIcon {
  height: 40px;
  width: 39px;
}

.overlap .group-27 {
  width: 1280px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 15px 0px;
}

.overlap .group-28 {
  background-image: url(../../static/img/dsc01610-2.png);
  background-size: cover;
  height: 283px;
  width: 50%;
}

.overlap .group-29 {
  background-image: url(../../static/img/img-6811-1.png);
  background-size: cover;
  height: 284px;
  width: 50%;
  margin-right: 20px
}
/* Media query para pantallas entre 700px y 320px de ancho */
@media (max-width: 700px) and (min-width: 320px) {
    .overlap .group-27 {
      flex-wrap: wrap;
      margin: 15px 0px 15px 0px;
    }

    .overlap .group-29 {
      width: 100%;
      margin: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 200px;
    }

    .overlap .group-28 {
      width: 100%;
      margin: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 200px;
    }

    .board-content-item-profile{
      flex-wrap: wrap;
    }
    /* Estilos específicos para diferentes tamaños dentro del rango */
    /* @media (max-width: 600px) {
        .container {
            padding: 15px;
        }

        h1 {
            font-size: 1.5em;
        }

        h2 {
            font-size: 1.3em;
        }
    }

    @media (max-width: 400px) {
        .container {
            padding: 10px;
        }

        h1 {
            font-size: 1.2em;
        }

        h2 {
            font-size: 1em;
        }

        p {
            font-size: 0.9em;
        }
    } */
}
</style>
