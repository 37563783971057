<template>
  <div class="overlap-wrapper">
    <div class="property-default banner">
      <!--  -->
      <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :pagination="{
        clickable: true,
      }" :modules="modules" class="mySwiper">
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/Brasil.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/Farm.png" alt="Farm Logo" class="logo" style="width: 100px;" />
              <p>FARM launches a collection inspired by the
                <br />
                brazilian tropicalism for the summer
              </p>
              <img src="../../static/img/slider/BrasilBandera.png" alt="Brazilian Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/Argentina.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/laMartina.png" alt="La Martina Logo" class="logo" />
              <p>Acquire this amazing
                <br />
                collection 2024
              </p>
              <img src="../../static/img/slider/ArgentinaBandera.png" alt="Argentina Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/Mexico.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/Tane.png" alt="Tane Logo" class="logo" />
              <p> TANE is an invitation to explore the magic
                <br />
                of Mexico through jewellery.
              </p>
              <img src="../../static/img/slider/MexicoBandera.png" alt="Argentina Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/colombia.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/Remu.png" alt="Remu Logo" class="logo" />
              <p> It’s finally getting cold enough to take the <br> Peguche Jacket for a stroll.
              </p>
              <img src="../../static/img/slider/EcuadorBandera.png" alt="Argentina Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/Mexico2.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/PinedaLogo.png" alt="Remu Logo" class="logo" />
              <p>
                5 Pineda Covalin accessories
                <br />
                must-haves in your wardrobe
              </p>
              <img src="../../static/img/slider/MexicoBandera.png" alt="Mexico Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="showcase-container">
            <div class="image-container">
              <img src="../../static/img/slider/Colombia2.png" alt="Farm Showcase" />
            </div>
            <div class="text-container">
              <img src="../../static/img/slider/MHLogo.png" alt="Remu Logo" class="logo" />
              <p>
                Elevate your looks with garments,
                <br />
                bags and accessories that tell stories.
              </p>
              <img src="../../static/img/slider/ColombiaBandera.png" alt="Mexico Flag" class="flag" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
  name: "OverlapWrapper",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style>
.overlap-wrapper {
  width: 100%;
}

.property-default {
  width: 100%;
}
.swiper-pagination-bullet{
  background: white !important;
  opacity: 60% !important;
  width: 20px;
  height: 20px;
}

.swiper-pagination-bullet-active{
  background: white !important;
  width: 100px;
  height: 20px;
  border-radius: 10px;
  opacity: 100% !important;
}

.swiper-pagination{
  text-align: left;
  padding-left: 15%;
  padding-bottom: 3%;
}

/* Slider */
.showcase-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.image-container {
  width: 50%;
}

.image-container img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.text-container {
  text-align: center;
  margin-left: 20px;
  width: 50%;
}

.text-container .logo {
  width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1em;
  margin: 10px 0;
}

.flag {
  width: 50px;
  height: auto;
  margin-top: 10px;
}

@media (max-width: 700px) and (min-width: 320px) {
  .swiper-pagination{
    text-align: center;
    padding-left: 0;
    padding-bottom: 0;
    background:#fff0;
  }
  .swiper-pagination-bullet{
    background: rgb(222, 222, 222) !important;
    opacity: 100% !important;
    width: 20px;
    height: 20px;
  }

}
</style>