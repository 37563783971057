<template>
  <div class="Footer">
    <div class="group">
      <div class="FooterMenu">
        <div class="about">ABOUT</div><br>
        <div class="text-wrapper-13">The History</div>
        <div class="text-wrapper-18">The Company</div>
      </div>
      <div class="FooterMenu">
        <div class="text-wrapper-22">CONTACT US</div><br>
        <div class="FooterContentContact">
          <img class="vector-8" alt="Vector" src="../../static/img/vector-11.svg" />
          <div class="text-wrapper-19">hola@sure.market</div>
        </div>
        <!-- <div class="text-wrapper-14">2 346678234</div>
          <div class="text-wrapper-15">Address 1</div> -->
        <!-- <div class="text-wrapper-16">Press</div> -->
        <!-- <img class="vector-6" alt="Vector" src="../../static/img/vector-9.svg" />
          <img class="vector-7" alt="Vector" src="../../static/img/vector-10.svg" /> -->
      </div>

      <div class="FooterMenu">
        <div class="text-wrapper-23">INFORMATION</div><br>
        <div class="text-wrapper-17">Privacy Policy</div>
        <div class="text-wrapper-21">Terms and Conditions</div>
      </div>
    </div>
    <div class="FooterSocialMedia">
      <!-- <a href="https://edu.gcfglobal.org/" style="display: none;"> <img class="img-2" alt="Facebook" src="../../static/img/Facebook.svg" /></a> -->
      <div class="overlap-7">
        <a href="https://www.instagram.com/sur_emarket"> <img alt="Instagram" class="Instagram"
            src="../../static/img/Instagram.svg" /></a>
      </div>
      <div class="img-wrapper">
        <a href="https://www.linkedin.com/company/sure-market/"> <img alt="Group" src="../../static/img/Link.svg" /></a>
      </div>
    </div>
    <div class="FooterLastPart">
      <div>
        <p class="text-wrapper-20">© SURe 2024. All rights reserved.</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.Footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
}
.FooterLastPart{
  display: flex;
    margin: 10px 0px 20px 0px;
}
.group {
  width: 90%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.FooterMenu {
  display: flex;
    width: 45%;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}

.FooterContentContact{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.Instagram{
  margin: 10px 10px 10px 0px;
}

.FooterSocialMedia {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.group .img-2 {
  height: 19px;
  width: 19px;
}

.group .overlap-7 {
  height: 19px;
  width: 19px;
}

.group .vector-3 {
  height: 9px;
  width: 9px;
}

.group .vector-4 {
  height: 3px;
  width: 3px;
}

.group .vector-5 {
  height: 19px;
  width: 19px;
}

.group .img-wrapper {
  height: 19px;
  width: 19px;
}

.group .img-3 {
  height: 10px;
  width: 11px;
}

.group .overlap-8 {
  background-image: url(../../static/img/vector-8.svg);
  background-size: 100% 100%;
  height: 19px;
  width: 19px;
}

.group .img-4 {
  height: 9px;
  width: 11px;
}

.group .text-wrapper-13 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.group .text-wrapper-14 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.group .text-wrapper-15 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.group .text-wrapper-16 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.group .text-wrapper-17 {
  color: var(--blue-1);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
  text-decoration: underline;
}

.group .text-wrapper-18 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.group .text-wrapper-19 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
}

.text-wrapper-20 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 1.5px;
  line-height: normal;
  white-space: nowrap;
}

.group .text-wrapper-21 {
  color: var(--blue-1);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  line-height: normal;
  text-decoration: underline;
}

.group .about {
  color: var(--black-principal);
  font-family: "Futura Bk BT-Book", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: normal;
  white-space: nowrap;
}

.group .text-wrapper-22 {
  color: var(--black-principal);
  font-family: "Futura Bk BT-Book", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: normal;
  white-space: nowrap;
}

.group .text-wrapper-23 {
  color: var(--black-principal);
  font-family: "Futura Bk BT-Book", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: normal;
  white-space: nowrap;
}

.group .rectangle-13 {
  background-color: var(--ffffff);
  height: 1px;
  width: 1020px;
}

.group .vector-6 {
  height: 12px;
  width: 7px;
}

.group .vector-7 {
  height: 11px;
  width: 7px;
}

.group .vector-8 {
  height: 8px;
  width: 12px;
  margin: 0px 10px 0px 0px;
}
@media (max-width: 700px) and (min-width: 320px) {
  .group {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .FooterMenu {
    margin: 10px 0px 10px 0px;
  }

}
</style>
