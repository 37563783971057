<template>
  <div class="header">
    <div class="overlap-4">
      <p class="text-wrapper-10">We are SURe you will not find another market like this in the world.</p>
    </div>
    <div class="overlap-group-5">
      <div class="HeaderFirst">
        <div class="HeaderFirstIteamsFlag">
          <img class="mask-group" alt="Mask group" src="../../static/img/mask-group.png" />
          <div class="text-wrapper-7">USD</div>
        </div>
        <div class="HeaderFirstIteamsUser">
          <img class="subtract" alt="Subtract" src="../../static/img/subtract.svg" />
        </div>
        <div class="HeaderFirstIteamsLove">
          <img class="group-11" alt="Group" src="../../static/img/group.png" />
        </div>
        <div class="HeaderFirstIteamsLogo">
          <img class="SURE-logo-ALARCON" alt="Sure logo ALARCON" src="../../static/img/logo.svg" />
        </div>
        <div class="HeaderFirstSearch">
          <div class="group-10">
            <div class="text-wrapper-9">Search</div>
            <div class="rectangle-9" />
          </div>
          <img class="vector-2" alt="Vector" src="../../static/img/vector-1.svg" />
        </div>
        <div class="HeaderFirstCar">
          <div class="text-wrapper-8">0</div>
          <img class="vector" alt="Vector" src="../../static/img/vector.svg" />
        </div>
      </div>
      <div class="group-wrapper">
        <div class="group-7">
          <div class="menuIteam">BEAUTY &amp; HEALTH</div>
          <div class="menuIteam">FASHION</div>
          <div class="menuIteam">FOOD &amp; BEVERAGES</div>
          <div class="menuIteam">BOOKS &amp; MEDIA</div>
          <div class="menuIteam">ARTS &amp; DESIGN</div>
          <div class="menuIteam">MANUFACTURE</div>
          <div class="menuIteam">EXPERIENCES</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style>
.header {
  width: 100%;
}

.HeaderFirst {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 10px 0px 20px 0px;
}

.HeaderFirstIteamsFlag {
  display: flex;
  width: 5%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.HeaderFirstIteamsUser {
  display: flex;
  width: 6%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.HeaderFirstIteamsLove {
  display: flex;
  width: 3%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.HeaderFirstIteamsLogo {
  display: flex;
  width: 60%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

}

.HeaderFirstCar {
  width: 3%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.HeaderFirstSearch {
  width: 15%;
  display: flex;
}

.header .overlap-group-5 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 2px solid #000;
}

.header .rectangle-8 {
  height: 2px;
  width: 1280px;
}

.header .group-wrapper {
  width: 100%;
  margin: 10px 0px 20px 0px;
}

.header .group-7 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
}

.menuIteam {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.8px;
  line-height: normal;
  white-space: nowrap;
  margin: 10px 35px 0px 0px;
  cursor: pointer;
}

.header .mask-group {
  height: 21px;
  width: 21px;
  cursor: pointer;
}

.header .text-wrapper-7 {
  color: var(--black-principal);
  font-family: "Gothic A1", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;
}

.header .text-wrapper-8 {
  color: var(--black-principal);
  font-family: "Futura Bk BT-Book", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  width: 8px;
  cursor: pointer;
}

.header .vector {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.header .group-10 {
  height: 16px;
  width: 152px;
}

.header .rectangle-9 {
  background-color: var(--black-principal);
  height: 1px;
  width: 150px;
}

.header .text-wrapper-9 {
  color: var(--black-principal);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  width: 150px;
  cursor: pointer;
}

.header .vector-2 {
  height: 15px;
  width: 14px;
  cursor: pointer;
}

.header .SURE-logo-ALARCON {
  height: 55px;
  width: 168px;
  cursor: pointer;
}

.header .group-11 {
  height: 14px;
  width: 15px;
  cursor: pointer;
}

.header .subtract {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.header .overlap-4 {
  background-image: url(../../static/img/rectangle-848.svg);
  height: 52px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.header .text-wrapper-10 {
  color: var(--ffffff);
  font-family: "Futura Lt BT-Light", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1.4px;
  line-height: normal;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
}
/* Media query para pantallas entre 700px y 320px de ancho */
@media (max-width: 700px) and (min-width: 320px) {
  .HeaderFirstIteamsLogo{
    width: 50%;
  }
  .HeaderFirstIteamsFlag {
    width: 10%;
  }
  .header .rectangle-9{
    width: 100%;
  }
  .header .text-wrapper-9{
    width: 100%;
  }
  .HeaderFirstCar{
    width: 10%;
  }
  .HeaderFirst{
    margin: 10px 0px 10px 0px;
  }
}
</style>
